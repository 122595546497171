class Playground
{
  scene;

  constructor(scene) {
    this.scene = scene;

    this.draw();
  }

  draw() {

  }
}

export {Playground};
