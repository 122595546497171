import React, {useEffect, useRef} from 'react';
import {Scene} from './scene/Scene';


const myScene = new Scene();

const ThreeScene = (props) =>
{
  const refCanvas = useRef(null);


  useEffect(() =>
  {
    if(refCanvas)
    {
      myScene.setDomEl(refCanvas.current);
    }
  }, [refCanvas]);


  return (
    <>
      <div className={'canvasCont'} ref={refCanvas} />
    </>
  );
};

export {ThreeScene};


