import {gsap} from 'gsap';
import * as THREE from 'three';
import {Vector3} from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/experimental/CameraControls';
import Stats from 'three/examples/jsm/libs/stats.module';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import {Playground} from './Playground';

import {GPUComputationRenderer} from 'three/examples/jsm/misc/GPUComputationRenderer';
import fragmentParticles from './shader/fragment.glsl';
import fragmentSimulation from './shader/fragmentSimulation.glsl';
import vertexParticles from './shader/vertex-particles.glsl';


const WIDTH = 400;

class Scene
{
  time = 0;
  domEl;
  cam;
  scene;
  renderer;
  stats = new Stats();

  playground;

  constructor() {
    this.initRenderer();


    this.playground = new Playground(this.scene);

  }


  initGPGPU() {
    this.gpuCompute = new GPUComputationRenderer(WIDTH, WIDTH, this.renderer);
    const dtPosition = this.gpuCompute.createTexture();

    this.fillPositions(dtPosition);

    this.positionVar = this.gpuCompute.addVariable('texturePosition', fragmentSimulation, dtPosition);

    this.positionVar.material.uniforms['time'] = {value: 0};

    this.positionVar.wrapS = THREE.RepeatWrapping;
    this.positionVar.wrapT = THREE.RepeatWrapping;

    this.gpuCompute.init();
  }


  fillPositions(texture) {
    console.log(texture);

    let arr = texture.image.data;

    for (let i = 0; i < arr.length; i = i + 4)
    {

      const rand = Math.floor(Math.random() * this.modelNumbers);

      const x = this.modelPos[3 * rand];
      const y = this.modelPos[3 * rand + 1];
      const z = this.modelPos[3 * rand + 2];

      //      const x = Math.random();
      //      const y = Math.random();
      //      const z = Math.random();

      arr[i] = x;
      arr[i + 1] = y;
      arr[i + 2] = z;
      arr[i + 3] = 1;
    }

    console.log(arr);

  }


  draw() {
    this.mat = new THREE.ShaderMaterial({
      extensions    : {
        derivatives: '#extension GL_OES_standard_derivatives : enable'
      },
      side          : THREE.DoubleSide,
      uniforms      : {
        time: {value: 0},
        //        time           : {type: 'f', value: 0},
        positionTexture: {value: null},
        resolution     : {value: new THREE.Vector4()}
      },
      vertexShader  : vertexParticles,
      fragmentShader: fragmentParticles
    });

    let geo = new THREE.BufferGeometry();
    let positions = new Float32Array(WIDTH * WIDTH * 3);
    let reference = new Float32Array(WIDTH * WIDTH * 2);
    for (let i = 0; i < WIDTH * WIDTH; i++)
    {

      const x = Math.random();
      const y = Math.random();
      const z = Math.random();

      const xx = (i % WIDTH) / WIDTH;
      const yy = ~~(i / WIDTH) / WIDTH;

      positions.set([x, y, z], i * 3);
      reference.set([xx, yy], i * 2);
    }

    geo.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    geo.setAttribute('reference', new THREE.BufferAttribute(positions, 3));


    //OVER
    geo = this.model.geometry;
//    geo = new THREE.IcosahedronBufferGeometry(1, 32);


    const ball = new THREE.Mesh(
      new THREE.SphereGeometry(.8),
      new THREE.MeshNormalMaterial()
    );

//    this.scene.add(ball);


    let mesh = new THREE.Points(geo, this.mat);

    this.group = new THREE.Group();
    this.group.add(mesh);

    this.scene.add(this.group);
  }

  initRenderer() {
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color('#181818');

    this.cam = new THREE.PerspectiveCamera(70, window.innerWidth / window.innerHeight, 0.1, 1000);
    this.cam.position.set(0, 0, 2);
    this.cam.lookAt(0, 0, 0);

    let pixelRatio = window.devicePixelRatio;
    let AA = true;
    if(pixelRatio > 1)
    {
      AA = false;
    }

    this.renderer = new THREE.WebGLRenderer({antialias: AA, powerPreference: 'high-performance'});

    this.renderer.encoding = THREE.sRGBEncoding;
    //    this.renderer.outputEncoding = THREE.AlphaFormat;

    this.renderer.setPixelRatio(1);
  }


  initOrbitControls() {
    this.debugOrbitControls = new OrbitControls(this.cam, this.renderer.domElement);
    this.debugOrbitControls.target = new Vector3(0, 0, 0);
    this.debugOrbitControls.update();
  }

  startAnimation() {
    if(!this.isAnimating)
    {
      this.isAnimating = true;
      gsap.ticker.add(() => this.animate());
    }
  }


  setDomEl(domEl) {
    this.domEl = domEl;

    if(domEl.children.length > 0)
    {
      domEl.firstElementChild.remove();
    }
    domEl.appendChild(this.renderer.domElement);

    domEl.appendChild(this.stats.dom);

    this.loader = new GLTFLoader();

    this.loader.load('./Lowhead.glb', (gltf) =>
    {
      console.log(gltf);

      const model = gltf.scene.children[0].children[1];
      const s = .05;
      model.geometry.scale(s, s, s);

      this.model = model;


      //      model.geometry.material = new THREE.MeshNormalMaterial();
      //      model.geometry.material.needsUpdate = true;

      this.modelPos = model.geometry.attributes.position.array;
      this.modelNumbers = this.modelPos.length / 3;
      console.log(this.modelPos);


      //      this.scene.add(model);


      this.initOrbitControls();
      this.setListener();

      this.initGPGPU();
      this.draw();


      this.onWindowResize(null);
      this.startAnimation();
    });


  }

  setListener() {
    window.addEventListener('resize', (e) => this.onWindowResize(e));
    window.addEventListener('keydown', (e) => this.handleKeyShortcut(e.key));
  }


  handleKeyShortcut(key) {
    let contentCont = document.getElementsByClassName('contentCont')[0];
    switch (key)
    {
      case 'Escape':
        break;
      case 'o':
        console.log('useOrbitCam');

        this.initOrbitControls();

        break;
      case 'd':

        break;
      default:
        break;
    }
  }


  animate() {
    this.stats.update();
    this.debugOrbitControls.update();
    this.mat.uniforms.time.value = this.time;

    this.group.rotation.y = Math.sin(this.time * .095);
//    this.group.rotation.y += .005;


    this.time += 0.05;
    this.positionVar.material.uniforms['time'].value = this.time;


    this.gpuCompute.compute();

    this.mat.uniforms.positionTexture.value = this.gpuCompute.getCurrentRenderTarget(this.positionVar).texture;


    this.renderer.render(this.scene, this.cam);
  }

  onWindowResize() {
    const canvas = this.renderer.domElement;
    const pixelRatio = window.devicePixelRatio;
    const width = canvas.clientWidth * pixelRatio | 0;
    const height = canvas.clientHeight * pixelRatio | 0;

    this.renderer.setSize(width, height, false);
    this.cam.aspect = width / height;
    this.cam.updateProjectionMatrix();
  }


}

export {Scene};
